<template>
    <div class="contact">
        <div class="container">
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="name" label="姓名"></el-table-column>
                <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
                <el-table-column align="center" prop="time" label="创建时间">
                    <template slot-scope="scope">{{scope.row.time.replace('T' ,' ')}}</template>
                </el-table-column>
                <el-table-column align="center" prop="content" label="留言内容"></el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="getList"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contact",
        data(){
            return{
                tableData:[],    //数据列表
                total:0,        //总条数
                page:1,         //当前页
                size:10,        //每页条数
                loading:false,   //loading框
            }
        },
        created(){
            this.getList(1);
        },
        methods:{
            async getList(page=1){
                this.page = page;
                this.loading = true;
                try {
                    const {data} = await this.$axios(`/api/commemt/show/${page}/${this.size}`);
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.data;
                        this.total = data.count_num;
                    }
                }catch (e) {
                    this.loading = false;
                    console.log(e);
                }
            },
        },
    }
</script>

<style scoped>

</style>